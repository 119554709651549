import {UserManagerSettings} from 'oidc-client';

export function getClientSettings(): UserManagerSettings {
  return {
    authority: 'https://test.hyperfontes.pixmo.cz/',
    client_id: 'HyperFontesTest',
    redirect_uri: 'https://test.hyperfontes.pixmo.cz/authentication/login-callback',
    post_logout_redirect_uri: 'https://test.hyperfontes.pixmo.cz/authentication/logout-callback',
    response_type: 'code',
    scope: 'HyperFontesAPI openid profile'
  };
}
